import './App.css';
import { React, useState, useEffect } from 'react';
import { Fade } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'


function App() {

  const [loading, setLoading] = useState(false);

  const [fadeImages, setFadeImages] = useState({
    images: [{
      url: ""
    }]
  });

async function getImages() {
  setLoading(true);
  fetch('https://screensaver.pmewebapps.pt/getFiles.php')
  .then(response => response.json())
  .then(data => setFadeImages(data))
  .catch(error => console.error(error));
  setLoading(false);
}

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const paramValue = urlParams.get('paramName');

useEffect(() => {
  getImages();
}, [paramValue]);

  const locale = 'pt-PT';
  const [today, setDate] = useState(new Date()); // Save the current date to be able to trigger an update

  useEffect(() => {
      const timer = setInterval(() => { // Creates an interval which will update the current data every minute
      // This will trigger a rerender every component that uses the useDate hook.
      setDate(new Date());
    }, 30 * 1000);
    return () => {
      clearInterval(timer); // Return a funtion to clear the timer so that it will stop being called on unmount
    }
  }, []);

  function Capitalize(str){
    return str.charAt(0).toUpperCase() + str.slice(1);
    }

  const day = Capitalize(today.toLocaleDateString(locale, { weekday: 'long' }));
  const date = `${day}, ${today.getDate()} de ${Capitalize(today.toLocaleDateString(locale, { month: 'long' }))}\n\n`;

  const hour = today.getHours();
  const wish = `${(hour < 12 && 'Bom dia') || (hour < 17 && 'Boa tarde') || 'Boa noite'}, `;

  const time = today.toLocaleTimeString(locale, { hour: 'numeric', hour12: false, minute: 'numeric' });

  return (
    <>
      <div className="time-container">
        <div className="time">{time}</div>
      </div>
      <div className="slider-opacity"></div>
      <Fade 
        arrows={false}
        duration={10000}
      >
        {fadeImages.images.map((fadeImage, index) => (
          <div key={index}>
            <img
              style={{ width: "100%", height: "100%" }}
              src={fadeImage.url}
            />
          </div>
        ))}
      </Fade>
      <div className="weekday-container">{date}</div>
    </>
  );
}

export default App;
